
import Dialog from "primevue/dialog";
import { defineComponent, ref } from "vue";
import MenuButton from "@/components/menu/MenuButton.vue";

export default defineComponent({
  name: "Help",
  components: {
    MenuButton,
    Dialog
  },
  setup() {
    const isHelpVisible = ref(false);

    return {
      toggleHelp: () => (isHelpVisible.value = !isHelpVisible.value),
      isHelpVisible
    };
  }
});
