
import { computed, defineComponent, PropType } from "vue";
import { MapNode, Point } from "@/types/graphics";
import { setTitleBoxes } from "@/components/map_layer/MapLayer";
import PinMarker from "@/components/pin_layer/PinMarker.vue";
import {
  WIDTH as PIN_MARKER_WIDTH,
  HEIGHT as PIN_MARKER_HEIGHT
} from "@/components/pin_layer/PinMarker.vue";
import SVGTextBox from "@/components/SVGTextBox.vue";
import { useStore } from "@/store";
import { mutations as titleBoxMutations, TitleBox } from "@/store/title_box";

const TITLE_PREFIX = "pin_title_";

export default defineComponent({
  name: "PinLayer",
  components: { SVGTextBox, PinMarker },
  emits: ["title-click", "title-over", "title-leave"],
  props: {
    layerId: String,
    pinNodes: {
      type: Object as PropType<MapNode[]>,
      required: true
    },
    selectedNodeId: {
      type: String,
      validator: (prop: string | null) =>
        typeof prop === "string" || prop === null,
      required: true
    },
    color: {
      type: String
    },
    fontWeight: {
      type: String
    },
    fontSize: {
      type: Number
    },
    textDecoration: {
      type: String
    }
  },
  setup(props, ctx) {
    const allPinNodes = computed(() => {
      const result: Record<string, MapNode> = {};
      for (const node of props.pinNodes) {
        result[node.id] = node;
      }
      return result;
    });
    const visiblePinNodes = computed(() => {
      const result: Record<string, MapNode> = {};
      for (const node of props.pinNodes) {
        if (node.id != props.selectedNodeId) {
          result[node.id] = node;
        }
      }
      return result;
    });

    const store = useStore();
    const layerID = "pin_" + props.layerId;
    const titleBox = computed(() => store.state.titleBox.layerMap[layerID]);
    // watch pin nodes and set its title boxes to titleBox store
    setTitleBoxes(
      TITLE_PREFIX,
      "left",
      allPinNodes,
      (titleBoxMap: Record<string, TitleBox>) => {
        store.commit(`titleBox/${titleBoxMutations.SET_MAP}`, {
          layerName: layerID,
          titleBoxMap
        });
      }
    );

    return {
      TITLE_PREFIX,
      PIN_MARKER_HEIGHT,
      PIN_MARKER_WIDTH,
      visiblePinNodes,
      titleBox,
      titleXY: computed(() => {
        const alignedXY: Record<string, Point> = {};
        for (const i in allPinNodes.value) {
          const node = allPinNodes.value[i];
          alignedXY[node.id] = {
            x: titleBox.value[node.id] ? titleBox.value[node.id].position.x : 0,
            y: titleBox.value[node.id] ? titleBox.value[node.id].position.y : 0
          };
        }
        return alignedXY;
      }),
      titleBoxClick: (nodeId: string) => {
        ctx.emit("title-click", { id: nodeId });
      },
      titleOver: (nodeId: string) => {
        ctx.emit("title-over", { id: nodeId });
      },
      titleLeave: (nodeId: string) => {
        ctx.emit("title-leave", { id: nodeId });
      }
    };
  }
});
