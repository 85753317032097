
import Dialog from "primevue/dialog";
import { ref, watch, defineComponent } from "vue";

export default defineComponent({
  name: "ChangeLogComplain",
  props: {
    show: Boolean,
    complainChangeLink: String
  },
  components: {
    Dialog
  },
  emits: ["hide"],
  setup(props) {
    const complainModalVisible = ref(false);
    watch(
      () => [props.show],
      async (newArgs, oldArgs) => {
        complainModalVisible.value = newArgs[0];
      }
    );
    return {
      complainModalVisible
    };
  }
});
