
import { actions, useStore } from "@/store";
import { actions as nodeContentActions } from "@/store/node_content";
import { computed, defineComponent, useCssModule } from "vue";
import TextArea from "primevue/textarea";
import SectionPreconditions from "./Preconditions.vue";
import SectionUsedBy from "./UsedBy.vue";
import { Tree } from "@/types/graphics";
import { EmptyNodeContent, NodeContent } from "@/store/node_content";
import { clone, printError } from "@/tools/utils";
import { useConfirm } from "primevue/useconfirm";
import api from "@/api/api";
import Markdown from "./Markdown.vue";
import ChangeLog from "./ChangeLog.vue";
import PinButton from "./PinButton.vue";
import SubscribeButton from "./SubscribeButton.vue";
import Title from "@/components/node_content/Title.vue";
import AddBasedOnButton from "@/components/node_content/AddBasedOnButton.vue";
import TitleImage from "@/components/node_content/TitleImage.vue";
import MarkdownIt from "markdown-it";
import { isWideScreen } from "../helpers";
import { add as textSearchAdd, SearchFieldName } from "@/tools/textsearch";
import MenuButton from "@/components/menu/MenuButton.vue";
const mdKatex = require('markdown-it-katex'); // eslint-disable-line
const mdImsize = require('markdown-it-imsize'); // eslint-disable-line
const mdVideo = require('markdown-it-block-embed'); // eslint-disable-line
const md = new MarkdownIt();
md.use(mdKatex, { output: "html" })
  .use(mdImsize)
  .use(mdVideo, {
    containerClassName: "video-embed",
    youtube: {
      width: 370,
      height: 209
    }
  });

export default defineComponent({
  name: "NodeContent",
  components: {
    MenuButton,
    TitleImage,
    AddBasedOnButton,
    Title,
    SectionUsedBy,
    Markdown,
    TextArea,
    SectionPreconditions,
    ChangeLog,
    PinButton,
    SubscribeButton
  },
  emits: ["select-precondition-is-on", "select-precondition-is-off"],
  props: {
    show: {
      type: Boolean,
      required: true
    },
    selectedNodeId: {
      type: String || null,
      validator: (prop: string | null) =>
        typeof prop === "string" || prop === null,
      required: true
    },
    clickedTitleId: {
      type: String,
      required: true
    },
    wrapperHeight: {
      type: Number,
      required: false
    }
  },
  setup(props) {
    const $style = useCssModule();
    const store = useStore();
    const confirm = useConfirm();
    const tree = store.state.tree;

    const selectedNode = computed<Tree | null>(() =>
      props.selectedNodeId && tree.nodeRecord[props.selectedNodeId]
        ? tree.nodeRecord[props.selectedNodeId].node
        : null
    );

    const selectedNodeContent = computed<NodeContent | null>(() => {
      if (
        props.selectedNodeId &&
        store.state.nodeContent.nodeContents[props.selectedNodeId]
      ) {
        return store.state.nodeContent.nodeContents[props.selectedNodeId];
      }
      const newContent = clone(EmptyNodeContent);
      newContent.nodeID = props.selectedNodeId;
      return newContent;
    });

    const selectedNodeComment = computed<string>(() =>
      props.selectedNodeId &&
      store.state.nodeContent.userNodeComments[props.selectedNodeId]
        ? store.state.nodeContent.userNodeComments[props.selectedNodeId].comment
        : ""
    );

    let contentBoxScrollTop = 0;
    return {
      editClickBegin: () => {
        const contentBox = document.getElementById("contentBox");
        if (contentBox) {
          contentBoxScrollTop = contentBox.scrollTop
        }
      },
      editClickFinish: () => {
        const contentBox = document.getElementById("contentBox");
        if (contentBox) {
          setTimeout(()=>{
            contentBox.scrollTop = contentBoxScrollTop+1
          }, 0);
        }
      },
      md,
      usedBy: computed(
        () => store.state.precondition.reverseIndex[props.selectedNodeId]
      ),
      editModeOn: computed(() => store.state.editModeOn),
      selectedNode,
      selectedNodeContent,
      checkAuthorized: async (e: Event) => {
        if (!store.state.user.user || store.state.user.user.isAnonymous) {
          await store.dispatch(`${actions.confirmSignInPopup}`, {
            confirm,
            message: "Please authorize to add comments and edit content"
          });
          e.preventDefault();
        }
      },
      selectedNodeComment,
      changeNodeTitle: async (value: string) => {
        if (!selectedNode.value || !selectedNode.value.id) {
          return;
        }
        const err = await api.debouncedUpdate({
          [`map/${selectedNode.value.id}/name`]: value
        });

        if (err) {
          printError(err.error.message, err.kv);
        }
      },
      changeContent: async (value: string) => {
        const err = await store.dispatch(
          `nodeContent/${nodeContentActions.setNodeContent}`,
          {
            nodeID: selectedNode.value!.id,
            content: value
          }
        );

        if (err) {
          printError(err, {});
        }
      },
      changeComment: async (value: string) => {
        textSearchAdd(
          selectedNode.value!.id,
          SearchFieldName.UserComment,
          value
        );
        const err = await store.dispatch(
          `nodeContent/${nodeContentActions.setNodeComment}`,
          {
            nodeID: selectedNode.value!.id,
            comment: value
          }
        );

        if (err) {
          printError(err, {});
        }
      },
      isWideScreen,
      wrapperClass: () => {
        return isWideScreen()
          ? $style.wrapperContent
          : $style.wrapperContentMobile;
      },
      basedOnClick: () => {
        const contentBox = document.getElementById('contentBox');
        const scrollToSection = document.getElementById('basedOnSection');
        if (contentBox && scrollToSection) {
          contentBox.scrollTop = scrollToSection.offsetTop;
        }
      },
      myCommentsClick: () => {
        const contentBox = document.getElementById('contentBox');
        const scrollToSection = document.getElementById('myCommentsSection');
        if (contentBox && scrollToSection) {
          contentBox.scrollTop = scrollToSection.offsetTop;
        }
      },
    };
  }
});
