
export default {
  setup() {
    const urlParams = new URLSearchParams(window.location.search);

    const from = new URL(decodeURI(urlParams.get("from")!));
    from.searchParams.append("forceDesktopOnMobile", "true");

    return {
      url: from.href
    };
  }
};
