
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { computed, watch, ref } from "vue";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import Markdown from "@/components/node_content/Markdown.vue";

export default {
  name: "Change",
  components: { Markdown },

  setup() {
    const store = useStore();
    const isAuthorized = computed(
      () => store.state.user.user && !store.state.user.user.isAnonymous
    );
    const route = useRoute();
    const change = ref({});

    watch(
      () => isAuthorized.value,
      async () => {
        if (isAuthorized.value) {
          // fetch change from firestore
          const docRef = doc(
            getFirestore(),
            "changes",
            route.params.id as string
          );
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            change.value = docSnap.data();
          }
        }
      },
      {
        immediate: true
      }
    );

    return {
      isAuthorized,
      route,
      change
    };
  }
};
