
import { defineComponent } from "vue";
import { isWideScreen } from "@/components/helpers";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
  name: "LogoDummy",
  methods: { isWideScreen },
  components: {
    ProgressBar
  },
  props: {
    wrapWidth: {
      type: Number
    },
    wrapHeight: {
      type: Number
    }
  }
});
