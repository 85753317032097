
import { defineComponent, PropType } from "vue";
import { Point } from "@/types/graphics";

export default defineComponent({
  name: "PreconditionArrow",
  props: {
    markerId: String,
    color: String,
    from: Object as PropType<Point>,
    to: (Object as PropType<Point>) || null
  }
});
