
import { defineComponent } from "vue";

export const WIDTH = 18;
export const HEIGHT = 26;

export default defineComponent({
  name: "PinMarker",
  props: {
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      WIDTH,
      HEIGHT
    };
  }
});
