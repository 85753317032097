
import RestoreNode from "@/components/menu/RestoreNode.vue";
import { defineComponent, PropType, ref } from "vue";
import {
  ActionType,
  ChangeLogEnriched,
  ChangeLogNodeParent,
  ChangeLogNodePrecondition
} from "@/store/change_log";
import { GetNodeLink, GetNodeHref } from "@/api/change_log";
import Card from "primevue/card";
import ChangeLogComplain from "@/components/menu/ChangeLogComplain.vue";
import Markdown from "@/components/node_content/Markdown.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "ChangeLogCard",
  components: {
    // Markdown,
    ChangeLogComplain,
    RestoreNode,
    Card
  },
  emits: [
    "restore-select-new-parent-is-on",
    "restore-select-new-parent-is-off"
  ],
  props: {
    clickedTitleId: {
      type: String,
      required: true
    },
    event: {
      type: Object as PropType<ChangeLogEnriched>,
      required: true
    }
  },
  computed: {
    ActionType() {
      return ActionType;
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const mapActions = [
      ActionType.ParentID,
      ActionType.Remove,
      ActionType.Restore
    ];
    const complainChangeLink = ref("");
    const complainModalVisible = ref(false);
    return {
      GetNodeHref: GetNodeHref,
      filterByNodeID: (nodeID: string) => {
        const query = {} as Record<string, string>;
        if (route.query.logFilterUserID) {
          query["logFilterUserID"] = route.query.logFilterUserID.toString();
        }
        if (route.query.logFilterActionType) {
          query[
            "logFilterActionType"
          ] = route.query.logFilterActionType.toString();
        }
        if (route.query.logFilterPeriod) {
          query["logFilterPeriod"] = route.query.logFilterPeriod.toString();
        }
        query["logFilterNodeID"] = nodeID;
        router.push({
          name: "node",
          params: { id: route.params.id },
          query
        });
      },
      filterByUserID: (userID: string) => {
        const query = {} as Record<string, string>;
        if (route.query.logFilterNodeID) {
          query["logFilterNodeID"] = route.query.logFilterNodeID.toString();
        }
        if (route.query.logFilterActionType) {
          query[
            "logFilterActionType"
          ] = route.query.logFilterActionType.toString();
        }
        if (route.query.logFilterPeriod) {
          query["logFilterPeriod"] = route.query.logFilterPeriod.toString();
        }
        query["logFilterUserID"] = userID;
        router.push({
          name: "node",
          params: { id: route.params.id },
          query
        });
      },
      mapActions,
      complainChangeLink,
      complainModalVisible,
      showComplain: (id: string) => {
        complainModalVisible.value = true;
        complainChangeLink.value = "https://scimap.org/change/" + id;
      },
      getContent: (event: ChangeLogEnriched) => {
        event = event as ChangeLogNodePrecondition;
        const removed = [];
        for (const cd of event.removed) {
          removed.push(GetNodeLink(cd.idPath, cd.id, cd.name));
        }
        const added = [];
        for (const cd of event.added) {
          added.push(GetNodeLink(cd.idPath, cd.id, cd.name));
        }

        let result = "";
        if (removed.length > 0) {
          result += `removed from "based on": ${removed.join(", ")}`;
        }
        if (added.length > 0) {
          result += `<br> added to "based on": ${added.join(", ")}`;
        }
        return result;
      },
      getMapActionDescription: (event: ChangeLogNodeParent): string => {
        if (event.action === ActionType.Restore) {
          return `node ${GetNodeLink(
            event.node.idPath,
            event.node.id,
            event.node.name
          )} was restored to ${GetNodeLink(
            event.parentNodeAfter!.idPath,
            event.parentNodeAfter!.id,
            event.parentNodeAfter!.name
          )}`;
        }
        if (event.isAdded) {
          return `node ${GetNodeLink(
            event.node.idPath,
            event.node.id,
            event.node.name
          )} was added to ${GetNodeLink(
            event.parentNodeAfter!.idPath,
            event.parentNodeAfter!.id,
            event.parentNodeAfter!.name
          )}`;
        }
        if (event.isRemoved) {
          return `node ${GetNodeLink(
            event.node.idPath,
            event.node.id,
            event.node.name
          )} was removed from ${GetNodeLink(
            event.parentNodeBefore!.idPath,
            event.parentNodeBefore!.id,
            event.parentNodeBefore!.name
          )}`;
        }

        return `node ${GetNodeLink(
          event.node.idPath,
          event.node.id,
          event.node.name
        )} was moved from ${GetNodeLink(
          event.parentNodeBefore!.idPath,
          event.parentNodeBefore!.id,
          event.parentNodeBefore!.name
        )} to ${GetNodeLink(
          event.parentNodeAfter!.idPath,
          event.parentNodeAfter!.id,
          event.parentNodeAfter!.name
        )}`;
      }
    };
  }
});
