
import { computed, ref } from "vue";
import { useStore } from "@/store";

export default {
  name: "Title",
  emits: ["content-changed"],
  props: {
    content: {
      type: String,
      required: true
    }
  },
  setup(props: any, ctx: any) {
    const renderedContent = computed(() => {
      return props.content;
    });
    const editOn = ref(false);
    const store = useStore();
    const txtarea = ref<HTMLDivElement | null>(null);

    return {
      editOn,
      txtarea,
      setEditOn: (val: boolean) => {
        if (
          !store.state.user ||
          !store.state.user.user ||
          store.state.user.user.isAnonymous
        ) {
          return;
        }
        editOn.value = val;
        if (val && txtarea.value) {
          txtarea.value.style.display = "block";
          txtarea.value.focus();
        }
        if (!val && txtarea.value) {
          txtarea.value.style.display = "none";
        }
      },
      renderedContent,
      changeContent: (value: string) => {
        ctx.emit("content-changed", value);
      }
    };
  }
};
