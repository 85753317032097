
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { computed, watch, ref } from "vue";
import Markdown from "@/components/node_content/Markdown.vue";
import firebase from "firebase/compat";

export default {
  name: "NodeDescription",
  components: { Markdown },

  setup() {
    const store = useStore();
    const isAuthorized = computed(
      () => store.state.user.user && !store.state.user.user.isAnonymous
    );
    const route = useRoute();
    const node = ref({} as any);

    watch(
      () => isAuthorized.value,
      async () => {
        if (isAuthorized.value) {
          // fetch change from firestore
          const docRef = firebase
            .database()
            .ref(`/map/${route.params.id}`)
            .once("value", data => {
              if (data.exists()) {
                // not in /trash, try to get from /map, /node_content, /precondition
                node.value["is_in_trash"] = false;

                // map
                node.value["map"] = data.val();

                // node_content
                firebase
                  .database()
                  .ref(`/node_content/${route.params.id}`)
                  .once("value", data => {
                    if (data.exists()) {
                      node.value["node_content"] = data.val();
                    }
                  });

                // precondition
                firebase
                  .database()
                  .ref(`/precondition/${route.params.id}`)
                  .once("value", data => {
                    if (data.exists()) {
                      node.value["precondition"] = data.val();
                    }
                  });
              } else {
                firebase
                  .database()
                  .ref(`/trash/${route.params.id}`)
                  .once("value", data => {
                    if (data.exists()) {
                      node.value = data.val();
                      node.value["is_in_trash"] = true;
                    }
                  });
              }
            });
        }
      },
      {
        immediate: true
      }
    );

    return {
      isAuthorized: isAuthorized,
      route,
      node
    };
  }
};
